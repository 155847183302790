"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var ItemTagIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 20 : _b;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 73 72", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: "url(#filter0_d_4317_31164)" },
            React.createElement("path", { d: "M16.5 32C16.5 20.9543 25.4543 12 36.5 12C47.5457 12 56.5 20.9543 56.5 32C56.5 43.0457 47.5457 52 36.5 52C25.4543 52 16.5 43.0457 16.5 32Z", fill: "white", fillOpacity: "0.08" }),
            React.createElement("path", { d: "M16.5 32C16.5 20.9543 25.4543 12 36.5 12C47.5457 12 56.5 20.9543 56.5 32C56.5 43.0457 47.5457 52 36.5 52C25.4543 52 16.5 43.0457 16.5 32Z", fill: "#084BE7" }),
            React.createElement("path", { d: "M16.5 32C16.5 20.9543 25.4543 12 36.5 12C47.5457 12 56.5 20.9543 56.5 32C56.5 43.0457 47.5457 52 36.5 52C25.4543 52 16.5 43.0457 16.5 32Z", fill: "url(#paint0_linear_4317_31164)", fillOpacity: "0.4" }),
            React.createElement("path", { d: "M16.8576 32C16.8576 21.1518 25.6518 12.3576 36.5 12.3576C47.3482 12.3576 56.1424 21.1518 56.1424 32C56.1424 42.8482 47.3482 51.6424 36.5 51.6424C25.6518 51.6424 16.8576 42.8482 16.8576 32Z", stroke: "url(#paint1_linear_4317_31164)", strokeWidth: "0.715206" }),
            React.createElement("path", { d: "M48.386 29.6829L35.6433 22.3259C35.4531 22.215 35.2427 22.1431 35.0243 22.1142C34.806 22.0854 34.5842 22.1002 34.3716 22.1579L24.9917 24.6713C24.777 24.7288 24.5939 24.8693 24.4828 25.0618C24.3717 25.2543 24.3415 25.4831 24.3991 25.6978L26.9124 35.0777C26.9687 35.2906 27.0668 35.4901 27.2009 35.6648C27.335 35.8395 27.5025 35.9857 27.6937 36.095L40.4364 43.452C40.627 43.5621 40.8375 43.6336 41.0558 43.6624C41.2741 43.6911 41.4959 43.6766 41.7085 43.6196C41.9212 43.5626 42.1205 43.4643 42.2952 43.3303C42.4699 43.1962 42.6164 43.0291 42.7265 42.8384L48.9997 31.9729C49.1098 31.7823 49.1812 31.5718 49.21 31.3535C49.2387 31.1353 49.2242 30.9134 49.1672 30.7008C49.1102 30.4881 49.0119 30.2888 48.8779 30.1141C48.7438 29.9395 48.5767 29.7929 48.386 29.6829ZM41.2742 42.0008L28.5316 34.6438L26.2351 26.0735L34.8055 23.7771L47.5482 31.1341L41.2742 42.0008ZM32.0687 28.415C32.1331 28.6552 32.1248 28.9091 32.0449 29.1445C31.9649 29.38 31.817 29.5864 31.6197 29.7378C31.4224 29.8892 31.1847 29.9787 30.9366 29.9949C30.6885 30.0112 30.4411 29.9535 30.2258 29.8292C30.0104 29.7048 29.8368 29.5195 29.7268 29.2965C29.6169 29.0734 29.5755 28.8228 29.6079 28.5763C29.6404 28.3298 29.7452 28.0984 29.9092 27.9115C30.0731 27.7245 30.2888 27.5904 30.529 27.526C30.8511 27.4397 31.1942 27.4849 31.483 27.6516C31.7717 27.8184 31.9824 28.093 32.0687 28.415Z", fill: "white" }),
            React.createElement("path", { d: "M29.7647 14.4286C29.7647 14.4286 25.5336 16.5926 25.0713 18.024C24.6089 19.4554 25.5386 21.8108 25.5386 21.8108C25.5386 21.8108 24.1199 19.7133 22.6775 19.2864C21.2351 18.8595 19.8075 19.2205 19.8075 19.2205C19.8075 19.2205 20.9835 18.3824 21.4459 16.951C21.9082 15.5196 20.9785 13.1642 20.9785 13.1642C20.9785 13.1642 22.3972 15.2617 23.8396 15.6886C25.282 16.1155 29.7647 14.4286 29.7647 14.4286Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_4317_31164", x: "0.5", y: "0", width: "72", height: "72", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "8" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_4317_31164" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_4317_31164", result: "shape" })),
            React.createElement("linearGradient", { id: "paint0_linear_4317_31164", x1: "56.5", y1: "51.8399", x2: "16.5", y2: "11.8399", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "white", stopOpacity: "0" }),
                React.createElement("stop", { offset: "1", stopColor: "white" })),
            React.createElement("linearGradient", { id: "paint1_linear_4317_31164", x1: "16.5", y1: "12", x2: "56.687", y2: "52.187", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "white" }),
                React.createElement("stop", { offset: "1", stopColor: "white", stopOpacity: "0" })))));
};
exports.default = ItemTagIcon;
