"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var DropdownMenu = __importStar(require("@radix-ui/react-dropdown-menu"));
var React = __importStar(require("react"));
var icons_1 = require("../../icons");
var util_1 = require("../../util");
function Dropdown(_a) {
    var label = _a.label, options = _a.options, selectedValue = _a.selectedValue, onOptionSelected = _a.onOptionSelected, children = _a.children, className = _a.className, _b = _a.alignEdge, alignEdge = _b === void 0 ? "right" : _b, _c = _a.zIndex, zIndex = _c === void 0 ? 10 : _c, dropdownClassName = _a.dropdownClassName, _d = _a.disabled, disabled = _d === void 0 ? false : _d, open = _a.open, setOpen = _a.setOpen, _e = _a.glassMorphism, glassMorphism = _e === void 0 ? false : _e;
    var _f = React.useState(false), isOpen = _f[0], setIsOpen = _f[1];
    var isDropdownOpen = open !== undefined ? open : isOpen;
    var toggleDropdown = setOpen !== undefined ? setOpen : setIsOpen;
    var onOptionClicked = function (value) {
        var selected = options.find(function (option) { return option.value === value; }) || {};
        toggleDropdown(false);
        onOptionSelected(selected);
    };
    return (React.createElement(DropdownMenu.Root, { open: isDropdownOpen, onOpenChange: toggleDropdown },
        React.createElement("div", { className: (0, util_1.customCN)("relative flex items-center", className) },
            React.createElement(DropdownMenu.Trigger, { disabled: disabled, asChild: true },
                React.createElement("div", { className: "h-auto w-auto cursor-pointer rounded-none tracking-normal focus-within:outline-none" }, children !== null && children !== void 0 ? children : (React.createElement("div", { className: "bg-overlay-800 flex items-center justify-between gap-sm rounded-sm px-md py-2.5" },
                    React.createElement("p", { className: "label-sm" }, selectedValue),
                    React.createElement(icons_1.ArrowDownIcon, { size: 16 }))))),
            React.createElement(DropdownMenu.Content, { className: (0, util_1.customCN)(glassMorphism ? "min-w-[240px]" : "min-w-[191px]", "mt-3 overflow-hidden rounded-sm shadow-[0px_4px_16px_0px_rgba(0,_0,_0,_0.12)]", glassMorphism
                    ? "border border-white/20 bg-white/30 backdrop-blur-2xl" // Glass effect classes
                    : "bg-white", dropdownClassName), style: { zIndex: zIndex }, align: alignEdge === "right" ? "end" : "start" },
                label && (React.createElement(DropdownMenu.Label, { className: "label-sm px-lg pt-md text-cn-tertiary" }, label)),
                React.createElement(DropdownMenu.RadioGroup, { value: selectedValue, onValueChange: onOptionClicked }, options.map(function (option, index) { return (React.createElement(React.Fragment, { key: option.value },
                    React.createElement(DropdownMenu.RadioItem, { value: option.value, className: (0, util_1.customCN)("label-md flex cursor-pointer flex-row items-center justify-between gap-2 whitespace-nowrap px-lg py-md focus-within:bg-gray-100 focus-within:outline-none hover:outline-none", glassMorphism ? "hover:bg-white/40" : "hover:bg-gray-100", option.className) },
                        option.label,
                        selectedValue === option.value ? (React.createElement(DropdownMenu.ItemIndicator, null,
                            React.createElement(icons_1.CheckIcon, null))) : (option === null || option === void 0 ? void 0 : option.icon) ? (option === null || option === void 0 ? void 0 : option.icon) : (React.createElement("div", { className: "h-4 w-4" }))),
                    index !== options.length - 1 && (React.createElement(DropdownMenu.Separator, { className: "last:border-b-none mx-sm h-[1px] border-b border-b-border-transparent last:h-0" })))); }))))));
}
exports.default = Dropdown;
