"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var tailwind_merge_1 = require("tailwind-merge");
var icons_1 = require("../../icons");
var bgClasses = {
    primary: "bg-bg-accent data-[loading=true]:bg-bg-tertiary disabled:bg-bg-tertiary",
    secondary: "bg-bg-tertiary",
    tertiary: "bg-transparent",
    mono: "bg-black",
};
var overlayClasses = {
    primary: "group-hover/btn:bg-overlay-200 group-active/btn:bg-overlay-200",
    secondary: "group-hover/btn:bg-overlay-inverse100 group-active/btn:bg-overlay-inverse200",
    tertiary: "group-hover/btn:bg-overlay-inverse100 group-active/btn:bg-overlay-inverse200",
    mono: "group-hover/btn:bg-overlay-inverse100 group-active/btn:bg-overlay-inverse200",
};
var textColorClasses = {
    primary: "text-cn-onColor",
    secondary: "text-cn-primary",
    tertiary: "text-cn-accent",
    mono: "text-cn-onColor",
};
var spinnerColors = {
    primary: "white",
    secondary: "black",
    tertiary: "black",
    mono: "white",
};
var ButtonLarge = function (_a) {
    var icon = _a.icon, _b = _a.iconPosition, iconPosition = _b === void 0 ? "right" : _b, _c = _a.variant, variant = _c === void 0 ? "primary" : _c, _d = _a.className, className = _d === void 0 ? "" : _d, _e = _a.textClassName, textClassName = _e === void 0 ? "" : _e, _f = _a.innerClassName, innerClassName = _f === void 0 ? "" : _f, style = _a.style, disabled = _a.disabled, isLoading = _a.isLoading, fullWidth = _a.fullWidth, onClick = _a.onClick, children = _a.children, remainingProps = __rest(_a, ["icon", "iconPosition", "variant", "className", "textClassName", "innerClassName", "style", "disabled", "isLoading", "fullWidth", "onClick", "children"]);
    return (React.createElement("button", __assign({}, remainingProps, { onClick: onClick, disabled: disabled, style: style, className: (0, tailwind_merge_1.twMerge)("group/btn flex h-fit w-full min-w-[165px] overflow-hidden rounded-sm", isLoading ? "justify-center" : "justify-between", fullWidth ? "w-full" : "max-w-[480px]", bgClasses[variant], className) }),
        React.createElement("div", { className: (0, tailwind_merge_1.twMerge)("flex h-full w-full items-center justify-center px-4 py-3 group-disabled/btn:text-cn-disabled", overlayClasses[variant], innerClassName) }, isLoading ? (
        // Have to add animate-spin because the animation is not piping through
        // [PHI-1683 : animate-spin not working due to tailwind style class clashing](https://linear.app/phiaco/issue/PHI-1683/animate-spin-not-working-due-to-tailwind-style-class-clashing)
        React.createElement(icons_1.Spinner, { color: spinnerColors[variant], className: "animate-spin" })) : (React.createElement(React.Fragment, null,
            icon && iconPosition === "left" ? icon : null,
            React.createElement("div", { className: (0, tailwind_merge_1.twMerge)("label-md grow text-center ".concat(textColorClasses[variant]), disabled ? "text-cn-disabled" : "", textClassName) }, children),
            icon && iconPosition === "right" ? icon : null)))));
};
exports.default = ButtonLarge;
