"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@phosphor-icons/react");
var React = __importStar(require("react"));
var tailwind_merge_1 = require("tailwind-merge");
var __1 = require("..");
var util_1 = require("../../util");
var ExtensionProductRow = function (_a) {
    var image = _a.image, heading = _a.heading, salePrice = _a.salePrice, originalPrice = _a.originalPrice, color = _a.color, className = _a.className, domain = _a.domain, productUrl = _a.productUrl, onClick = _a.onClick, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, _c = _a.size, size = _c === void 0 ? "default" : _c;
    var iconSize = size === "small" ? 12 : size === "default" ? 20 : 20;
    if (isLoading) {
        return (React.createElement("div", { className: "flex cursor-pointer items-center justify-between px-lg" },
            React.createElement("div", { className: (0, tailwind_merge_1.twMerge)("flex w-full items-center gap-3 border-b border-gray-100 py-3", className) },
                React.createElement("div", { className: "h-16 w-16 flex-shrink-0 overflow-hidden rounded-md" },
                    React.createElement(__1.Skeleton, { className: "h-full w-full", innerClassName: "" })),
                React.createElement("div", { className: "min-w-0 flex-1" },
                    React.createElement(__1.Skeleton, { className: "mb-2 h-4 w-3/4", innerClassName: "" }),
                    React.createElement("div", { className: "flex items-center gap-2" },
                        React.createElement(__1.Skeleton, { className: "h-3 w-16", innerClassName: "" }),
                        React.createElement(__1.Skeleton, { className: "h-3 w-20", innerClassName: "" })))),
            React.createElement("div", { className: "flex h-full w-full items-center justify-end" },
                React.createElement(react_1.CaretRight, { size: iconSize * 0.8, className: "text-gray-300" }))));
    }
    return (React.createElement("a", { href: productUrl, target: "_blank", rel: "noopener noreferrer", className: "block" },
        React.createElement("div", { className: "flex cursor-pointer items-center justify-between border-b border-gray-100 px-lg transition-colors hover:rounded-sm hover:border-white hover:bg-gray-50" },
            React.createElement("div", { className: (0, tailwind_merge_1.twMerge)("flex flex-grow items-center gap-3 overflow-hidden py-3 pr-4", className), onClick: onClick },
                React.createElement("div", { className: "h-16 w-16 flex-shrink-0 overflow-hidden rounded-md" },
                    React.createElement(__1.AspectRatio, { ratio: 1 },
                        React.createElement("img", { src: image, alt: heading, className: "h-full w-full object-cover" }))),
                React.createElement("div", { className: "min-w-0 max-w-[calc(100%-40px)] flex-1 overflow-hidden" },
                    React.createElement("div", { className: "relative overflow-hidden whitespace-nowrap" },
                        React.createElement("h3", { className: "pr-6 text-sm font-medium text-gray-900" }, heading)),
                    React.createElement("div", { className: "relative mt-1 flex items-center gap-2 overflow-hidden whitespace-nowrap" },
                        React.createElement("div", { className: "flex flex-shrink-0 items-center gap-1" },
                            React.createElement("span", { className: "text-sm font-medium text-[".concat(color, "]") }, (0, util_1.formatCurrencyString)(salePrice)),
                            originalPrice && (React.createElement("span", { className: "text-sm text-gray-500 line-through" }, (0, util_1.formatCurrencyString)(originalPrice)))),
                        React.createElement("span", { className: "flex-shrink-0 text-sm text-gray-500" }, "\u2022"),
                        React.createElement("span", { className: "pr-6 text-sm text-gray-500" }, domain ? domain.charAt(0).toUpperCase() + domain.slice(1) : "")))),
            React.createElement("div", { className: "flex flex-shrink-0 items-center justify-end pl-2" },
                React.createElement(react_1.CaretRight, { size: iconSize * 0.8, className: "text-gray-600" })))));
};
exports.default = ExtensionProductRow;
