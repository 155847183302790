"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhiaNameLogo = void 0;
var React = __importStar(require("react"));
var util_1 = require("../util");
var PhiaNameLogo = function (_a) {
    var className = _a.className, _b = _a.width, width = _b === void 0 ? 45 : _b, _c = _a.height, height = _c === void 0 ? 24 : _c, props = __rest(_a, ["className", "width", "height"]);
    return (React.createElement("svg", __assign({ className: (0, util_1.customCN)("fill-black opacity-100 ", className), width: width, height: height, viewBox: "0 0 48 26", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M24.2337 20.8066C22.7549 20.8066 22.729 19.3905 23.0922 17.8457L24.571 11.3318C24.9083 9.91576 25.1677 8.49969 24.13 8.49969C22.1901 8.49969 18.9098 14.0781 17.553 20.4389C17.5457 20.4731 17.5154 20.4976 17.4802 20.4976H14.9339C14.8867 20.4976 14.8515 20.4546 14.8612 20.4088L18.3967 3.71083C18.729 2.11264 18.482 1.53925 17.631 1.30109C17.6103 1.29532 17.5982 1.27359 17.6049 1.25341L17.6306 1.17715C17.6391 1.15184 17.6607 1.13307 17.6871 1.12806L21.7171 0.36377H21.7292C21.7765 0.36377 21.8117 0.406938 21.8019 0.452782L18.9543 13.7614C18.945 13.8048 19.008 13.8244 19.0263 13.7839C20.9546 9.51823 23.1342 6.69743 25.7904 6.69743C27.8918 6.69743 27.8918 8.86015 27.2691 11.5378L25.8422 17.6655C25.7125 18.2062 25.5569 18.9013 25.972 18.9013C26.2682 18.9013 26.875 18.4561 27.708 17.4538C27.7349 17.4215 27.7837 17.4173 27.8153 17.4451L27.9418 17.5566C27.9683 17.58 27.9743 17.6191 27.9562 17.6494C26.7613 19.6487 25.4674 20.8066 24.2337 20.8066Z", fill: "#141414" }),
        React.createElement("path", { d: "M33.6753 4.72988C32.7154 4.72988 31.963 3.98323 31.963 3.03061C31.963 2.10373 32.7154 1.35708 33.6753 1.35708C34.6093 1.35708 35.3616 2.10373 35.3616 3.03061C35.3616 3.98323 34.6093 4.72988 33.6753 4.72988Z", fill: "#141414" }),
        React.createElement("path", { d: "M30.5361 20.7958C29.0054 20.7958 29.1092 19.0965 29.3946 17.8349L31.1069 9.82771C31.2366 9.26128 31.3663 8.59187 30.9512 8.59187C30.6694 8.59187 30.1885 9.02314 29.5235 9.79561C29.4929 9.83117 29.437 9.83014 29.408 9.79326L29.311 9.66976C29.2925 9.64624 29.2903 9.61369 29.3052 9.58781C30.3604 7.76179 31.6169 6.68663 32.7673 6.68663C34.2201 6.68663 34.1682 8.12843 33.8569 9.64748L32.1446 17.6547L32.1396 17.6778C32.0595 18.0481 31.8938 18.8133 32.3003 18.8133C32.6608 18.8133 33.2996 18.2456 33.9899 17.4418C34.0161 17.4113 34.0621 17.4068 34.0935 17.432L34.2394 17.5487C34.268 17.5716 34.2753 17.6122 34.2566 17.6437C33.067 19.6399 31.7915 20.7958 30.5361 20.7958Z", fill: "#141414" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M43.0443 16.0312C41.4487 19.3167 39.7755 20.7914 37.7497 20.7914C36.1134 20.7914 34.9722 19.3745 34.9722 16.85C34.9722 12.1873 38.2968 6.67448 43.2288 6.67448C44.4702 6.67448 46.7801 7.43922 47.4249 8.14651C47.4544 8.1789 47.4641 8.22335 47.4549 8.26608L45.4481 17.653C45.3677 18.0501 45.1711 18.8133 45.5863 18.8133C45.9468 18.8133 46.6624 18.2456 47.3528 17.4418C47.3789 17.4113 47.4249 17.4068 47.4563 17.432L47.6023 17.5487C47.6309 17.5716 47.6381 17.6122 47.6194 17.6437C46.4299 19.6399 45.1544 20.7958 43.899 20.7958C43.4967 20.7958 43.2063 20.6779 43.002 20.4812C42.4291 19.9295 42.5332 18.7577 42.7436 17.8277L43.1198 16.0542C43.1292 16.0103 43.0625 15.99 43.0443 16.0312ZM44.0864 11.1497C43.1779 15.6836 40.6429 18.8293 38.9296 18.8293C38.0211 18.8293 37.4018 18.0972 37.4018 16.2424C37.4018 12.1207 40.1149 7.47735 42.7615 7.47735C44.6341 7.47735 44.3645 9.83888 44.0864 11.1497Z", fill: "#141414" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.3228 6.68799C13.335 6.68799 14.7768 8.7733 14.7768 11.7339C14.7768 16.162 11.6097 20.796 6.74427 20.796C6.32576 20.796 5.51128 20.7258 4.96778 20.5406C4.92698 20.5267 4.88269 20.551 4.87388 20.5932C4.87387 20.5932 4.87387 20.5932 4.87387 20.5933C4.87381 20.5937 4.8621 20.666 4.22961 23.6846C3.88384 25.3348 3.66893 26.0553 4.56493 26.1247C4.59134 26.1288 4.61058 26.1534 4.60626 26.1798L4.58255 26.3246C4.57885 26.3472 4.55933 26.3638 4.53644 26.3638H0.161966C0.133132 26.3638 0.111181 26.3379 0.115867 26.3095L0.14656 26.1533C0.150511 26.1293 0.169677 26.1106 0.193617 26.1064C1.00552 25.9937 1.04859 25.6539 1.47103 23.6979C2.57267 18.5967 3.4536 14.5229 4.5369 9.51616C4.54357 9.48536 4.51241 9.4601 4.48372 9.47312C4.0365 9.67601 3.57951 9.90648 3.10176 10.1645C3.06001 10.1871 3.00792 10.167 2.99236 10.1221L2.882 9.80427C2.87124 9.77325 2.88137 9.7388 2.90726 9.71862C3.50009 9.25653 4.09649 8.84142 4.70438 8.47329C4.74101 8.45111 4.7669 8.41479 4.77567 8.37287L5.13124 6.6741C5.13754 6.644 5.15825 6.61892 5.18663 6.60705L7.97204 5.44166C8.00678 5.42712 8.04356 5.45756 8.03578 5.49441L7.71001 7.0375C7.70403 7.06582 7.73035 7.09034 7.75822 7.08252C8.6388 6.83552 9.5177 6.68799 10.3228 6.68799ZM12.1246 11.9656C12.1246 15.956 9.88042 19.9993 7.23345 19.9993C5.36888 19.9993 5.4191 17.9816 5.68873 16.7026C7.41687 8.50526 7.43249 8.43122 7.43249 8.4312C7.43249 8.4312 7.43249 8.4312 7.43249 8.4312C7.43828 8.40338 7.45899 8.38095 7.48631 8.37315C8.08574 8.20209 8.58494 8.12853 9.08746 8.12853C11.1471 8.12853 12.1246 9.44266 12.1246 11.9656Z", fill: "#141414" })));
};
exports.PhiaNameLogo = PhiaNameLogo;
exports.default = exports.PhiaNameLogo;
