"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var AccentCheckCircleIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 24 : _b;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 66 66", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: "url(#filter0_d_3887_59969)" },
            React.createElement("path", { d: "M13 30C13 18.9543 21.9543 10 33 10C44.0457 10 53 18.9543 53 30C53 41.0457 44.0457 50 33 50C21.9543 50 13 41.0457 13 30Z", fill: "white", fillOpacity: "0.08" }),
            React.createElement("path", { d: "M13 30C13 18.9543 21.9543 10 33 10C44.0457 10 53 18.9543 53 30C53 41.0457 44.0457 50 33 50C21.9543 50 13 41.0457 13 30Z", fill: "#1F7045" }),
            React.createElement("path", { d: "M13 30C13 18.9543 21.9543 10 33 10C44.0457 10 53 18.9543 53 30C53 41.0457 44.0457 50 33 50C21.9543 50 13 41.0457 13 30Z", fill: "url(#paint0_linear_3887_59969)", fillOpacity: "0.4" }),
            React.createElement("path", { d: "M13.4 30C13.4 19.1752 22.1752 10.4 33 10.4C43.8248 10.4 52.6 19.1752 52.6 30C52.6 40.8248 43.8248 49.6 33 49.6C22.1752 49.6 13.4 40.8248 13.4 30Z", stroke: "url(#paint1_linear_3887_59969)", strokeWidth: "0.8" }),
            React.createElement("path", { d: "M44.6525 27.6625L28.2285 37.1449C28.1332 37.2 28.0279 37.2358 27.9187 37.2502C27.8095 37.2646 27.6985 37.2574 27.5921 37.2288C27.4857 37.2003 27.386 37.1511 27.2986 37.0841C27.2113 37.017 27.138 36.9334 27.083 36.8379L22.9344 29.6525C22.8232 29.4598 22.7931 29.2309 22.8507 29.0161C22.9082 28.8013 23.0488 28.6181 23.2414 28.5069C23.434 28.3957 23.6629 28.3656 23.8777 28.4231C24.0926 28.4807 24.2757 28.6212 24.3869 28.8139L28.1159 35.2741L43.8139 26.21C44.0065 26.0988 44.2354 26.0687 44.4502 26.1262C44.6651 26.1838 44.8482 26.3243 44.9594 26.517C45.0706 26.7096 45.1008 26.9385 45.0432 27.1533C44.9857 27.3681 44.8451 27.5513 44.6525 27.6625Z", fill: "white" }),
            React.createElement("path", { d: "M26.2647 12.4286C26.2647 12.4286 22.0336 14.5926 21.5713 16.024C21.1089 17.4554 22.0386 19.8108 22.0386 19.8108C22.0386 19.8108 20.6199 17.7133 19.1775 17.2864C17.7351 16.8595 16.3075 17.2205 16.3075 17.2205C16.3075 17.2205 17.4835 16.3824 17.9459 14.951C18.4082 13.5196 17.4785 11.1642 17.4785 11.1642C17.4785 11.1642 18.8972 13.2617 20.3396 13.6886C21.782 14.1155 26.2647 12.4286 26.2647 12.4286Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_3887_59969", x: "0.2", y: "0.4", width: "65.6", height: "65.6", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "3.2" }),
                React.createElement("feGaussianBlur", { stdDeviation: "6.4" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_3887_59969" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_3887_59969", result: "shape" })),
            React.createElement("linearGradient", { id: "paint0_linear_3887_59969", x1: "53", y1: "49.8399", x2: "13", y2: "9.83993", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "white", stopOpacity: "0" }),
                React.createElement("stop", { offset: "1", stopColor: "white" })),
            React.createElement("linearGradient", { id: "paint1_linear_3887_59969", x1: "13", y1: "10", x2: "53.187", y2: "50.187", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "white" }),
                React.createElement("stop", { offset: "1", stopColor: "white", stopOpacity: "0" })))));
};
exports.default = AccentCheckCircleIcon;
