"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var _1 = require(".");
var FullArrowDownIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 20 : _b, _c = _a.color, color = _c === void 0 ? "currentColor" : _c;
    return (React.createElement("svg", { className: className, width: (0, _1.getIconSize)(size), height: (0, _1.getIconSize)(size), viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.0675 11.6922L10.4425 17.3172C10.3845 17.3753 10.3156 17.4214 10.2397 17.4529C10.1638 17.4843 10.0825 17.5005 10.0003 17.5005C9.91821 17.5005 9.83688 17.4843 9.76101 17.4529C9.68514 17.4214 9.61621 17.3753 9.55816 17.3172L3.93316 11.6922C3.81588 11.5749 3.75 11.4159 3.75 11.25C3.75 11.0841 3.81588 10.9251 3.93316 10.8078C4.05044 10.6905 4.2095 10.6247 4.37535 10.6247C4.5412 10.6247 4.70026 10.6905 4.81753 10.8078L9.37535 15.3664V3.125C9.37535 2.95924 9.4412 2.80027 9.55841 2.68306C9.67562 2.56585 9.83459 2.5 10.0003 2.5C10.1661 2.5 10.3251 2.56585 10.4423 2.68306C10.5595 2.80027 10.6253 2.95924 10.6253 3.125V15.3664L15.1832 10.8078C15.3004 10.6905 15.4595 10.6247 15.6253 10.6247C15.7912 10.6247 15.9503 10.6905 16.0675 10.8078C16.1848 10.9251 16.2507 11.0841 16.2507 11.25C16.2507 11.4159 16.1848 11.5749 16.0675 11.6922Z", fill: color })));
};
exports.default = FullArrowDownIcon;
